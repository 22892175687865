import { Grid } from '@mui/material'
import { useState, useEffect, useMemo, useContext } from 'react'
import { Input, DatePicker, Typography, PhoneInput, Select, PopUp, AddressAutoComplete } from 'components'
import moment from 'moment'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { USER_ROLES } from 'helpers/constants'
import { UserContext } from 'context'

const UserForm = (props) => {
  const { onSubmit, open, hidePopup, isEdit = false, users, roles } = props
  const phoneRegex = /^(1-)?\d{3}-\d{3}-\d{4}$/
  const [selectedValue, setSelectedValue] = useState(users?.role)
  const { user } = useContext(UserContext)
  const isSuperAdmin = user.role === USER_ROLES.super_admin
  const roleOptions = useMemo(() => {
    return roles?.map((role) => {
      return {
        value: role.name,
        label: role.pretty_name
      }
    })
  }, [roles])
  const selectedRole = ['admin', 'super_admin'].includes(selectedValue)
  const isDisplay = !selectedRole

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('First Name is required'),
    last_name: Yup.string().required('Last Name is required'),
    title: Yup.string().required('Title is required'),
    role: Yup.object().required('Role is required'),
    date_of_birth: Yup.string()
      .nullable()
      .when('role', {
        is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
        then: () => Yup.string().required('Date of Birth is required')
      }),
    date_joined_rare: Yup.string()
      .nullable()
      .when('role', {
        is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
        then: () => Yup.string().required('Date Joined is required')
      }),
    // date_incorporated: Yup.string().nullable(),
    // name_incorporated: Yup.string().nullable(),
    // address: Yup.string()
    //   .nullable()
    //   .when('role', {
    //     is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
    //     then: () => Yup.string().required('Address is required')
    //   }),
    phone_number: Yup.string()
      .required('Phone number is required')
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value))
      .matches(phoneRegex, 'Please enter a valid phone number'),
    email: Yup.string().email('Email address must be a valid email').required('Email Address is required'),
    // sin_number: Yup.string()
    //   .nullable()
    //   .when('role', {
    //     is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
    //     then: () => Yup.string().required('SIN Number is required')
    //   }),
    // hst_number: Yup.string()
    //   .nullable()
    //   .when('role', {
    //     is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
    //     then: () => Yup.string().required('HST Number is required')
    //   }),
    // treb_number: Yup.string()
    //   .nullable()
    //   .when('role', {
    //     is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
    //     then: () => Yup.string().required('TREB Number is required')
    //   }),
    reco_number: Yup.string()
      .nullable()
      .when('role', {
        is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
        then: () => Yup.string().required('RECO Number is required')
      }),
    reco_expiry: Yup.string()
      .nullable()
      .when('role', {
        is: (role) => role?.value !== USER_ROLES.admin && role?.value !== USER_ROLES.super_admin,
        then: () => Yup.string().required('RECO Expiry Date is required')
      }),
    emergency_contact_phone: Yup.string()
      .transform((value, defaultValue) => (defaultValue === '' ? undefined : value))
      .matches(phoneRegex, 'Please enter a valid phone number')
  })

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      first_name: users?.first_name,
      last_name: users?.last_name,
      title: users?.title,
      role: roleOptions?.find((d) => d.value === users?.role),
      date_of_birth: users?.date_of_birth,
      date_joined_rare: users?.date_joined_rare,
      // date_incorporated: users?.date_incorporated,
      // name_incorporated: users?.name_incorporated,
      phone_number: users?.phone_number,
      email: users?.email,
      // sin_number: users?.sin_number,
      // hst_number: users?.hst_number,
      // treb_number: users?.treb_number,
      reco_number: users?.reco_number,
      reco_expiry: users?.reco_expiry,
      emergency_contact_name: users?.emergency_contact_name,
      emergency_contact_phone: users?.emergency_contact_phone,
      emergency_contact_relationship: users?.emergency_contact_relationship
    },
    resolver: yupResolver(validationSchema)
  })

  // Reset the form when the user prop changes
  useEffect(() => {
    reset({
      first_name: users?.first_name || '',
      last_name: users?.last_name || '',
      title: users?.title || '',
      role: roleOptions?.find((d) => d.value === users?.role) || null,
      date_of_birth: users?.date_of_birth || null,
      date_joined_rare: users?.date_joined_rare || '',
      // date_incorporated: users?.date_incorporated || '',
      // name_incorporated: users?.name_incorporated || '',
      phone_number: users?.phone_number || '',
      email: users?.email || '',
      // address: users?.address,
      // sin_number: users?.sin_number || '',
      // hst_number: users?.hst_number || '',
      // treb_number: users?.treb_number || '',
      reco_number: users?.reco_number || '',
      reco_expiry: users?.reco_expiry || '',
      emergency_contact_name: users?.emergency_contact_name || '',
      emergency_contact_phone: users?.emergency_contact_phone || '',
      emergency_contact_relationship: users?.emergency_contact_relationship || ''
    })
    setSelectedValue(users?.role)
  }, [users, reset, roleOptions])

  const handleCancel = () => {
    hidePopup()
    reset()
    setSelectedValue(null)
  }

  const handleFormSubmit = () => {
    handleSubmit((data) => {
      props.onSubmit(data, reset, setSelectedValue)
    })()
  }
  return (
    <PopUp
      title={isEdit ? 'Edit User' : 'Create User'}
      open={open}
      confirmBtnLabel={'Submit'}
      cancelBtnLabel={'Cancel'}
      onCancel={handleCancel}
      onConfirm={handleFormSubmit}
      fullWidth>
      <form id="createUser">
        <Typography.Heading type="6">Personal information</Typography.Heading>
        <Grid container columnSpacing={7} sx={{ mt: 1 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="first_name"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="First name"
                  required
                  name="first_name"
                  style={{ width: '100%' }}
                  disabled={isEdit}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item mt={{ xs: 3, md: 0 }}>
            <Controller
              name="last_name"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Last name"
                  required
                  name="last_name"
                  style={{ width: '100%' }}
                  disabled={isEdit}
                />
              )}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={7} sx={{ mt: 1 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="title"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Designation"
                  required
                  name="title"
                  style={{ width: '100%' }}
                  disabled={isEdit}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item mt={{ xs: 1, md: 0 }}>
            <Controller
              name="role"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Select
                  type={5}
                  options={roleOptions}
                  onChange={(e) => {
                    setSelectedValue(e.value)
                    onChange(e)
                  }}
                  value={value}
                  error={error?.message}
                  label="Roles"
                  placeholder="Select role"
                  required
                  name="role"
                />
              )}
            />
          </Grid>
        </Grid>

        {isDisplay && (
          <>
            <Grid container columnSpacing={7} sx={{ mt: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="date_of_birth"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Date of birth"
                      required
                      name="date_of_birth"
                      maxDate={new Date()}
                      style={{ width: '100%' }}
                      disabled={isEdit}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={6} item mt={{ xs: 1, md: 0 }}>
                <Controller
                  name="date_joined_rare"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Date joined RARE"
                      required
                      name="date_joined_rare"
                      style={{ width: '100%' }}
                      disabled={isEdit}
                    />
                  )}
                />
              </Grid>
            </Grid>
            {/* <Grid container columnSpacing={7} sx={{ mt: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="date_incorporated"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Date incorporated"
                      name="date_incorporated"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} md={6} item>
                <Controller
                  name="name_incorporated"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Name of the incorporation"
                      name="name_incorporated"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid> */}
          </>
        )}
        <Grid container columnSpacing={7} sx={{ mt: 1 }}>
          <Grid xs={12} md={6} item>
            <Controller
              name="phone_number"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <PhoneInput
                  name="phone_number"
                  label="Phone number"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  style={{ width: '100%' }}
                  required
                  disabled={isEdit}
                />
              )}
            />
          </Grid>
          <Grid xs={12} md={6} item>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Input
                  type="text"
                  error={error?.message}
                  value={value}
                  onChange={onChange}
                  label="Email address"
                  required
                  name="email"
                  style={{ width: '100%' }}
                  // disabled={isSuperAdmin ? false : isEdit ? true : false}
                  disabled={isEdit}
                />
              )}
            />
          </Grid>
        </Grid>

        {isDisplay && (
          <>
            {/* <Grid container columnSpacing={7} sx={{ mt: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="address"
                  control={control}
                  defaultValue={users?.address ?? ''}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <AddressAutoComplete
                      value={value}
                      required
                      name="address"
                      onChange={onChange}
                      error={error?.message}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Controller
                  name="sin_number"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="SIN Number"
                      required
                      name="sin_number"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid> */}
            {/* <Grid container columnSpacing={7} sx={{ mt: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="hst_number"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="HST Number"
                      required
                      name="hst_number"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} md={6} item>
                <Controller
                  name="treb_number"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="TREB Number"
                      required
                      name="treb_number"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid> */}

            <Grid container columnSpacing={7} sx={{ mt: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="reco_number"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="RECO number"
                      required
                      name="reco_number"
                      style={{ width: '100%' }}
                      disabled={isEdit}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} md={6} item>
                <Controller
                  name="reco_expiry"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <DatePicker
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="RECO Expiry date"
                      required
                      name="reco_expiry"
                      style={{ width: '100%' }}
                      minDate={moment().add(1, 'day').toDate()}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Typography.Heading type="6" style={{ marginTop: 16 }}>
              Emergency contact
            </Typography.Heading>

            <Grid container columnSpacing={7} sx={{ mt: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="emergency_contact_name"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Name (optional)"
                      name="emergency_contact_name"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>

              <Grid xs={12} md={6} item>
                <Controller
                  name="emergency_contact_phone"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <PhoneInput
                      name="emergency_contact_phone"
                      label="Phone number (optional)"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={7} sx={{ mt: 1, mb: 1 }}>
              <Grid xs={12} md={6} item>
                <Controller
                  name="emergency_contact_relationship"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Input
                      type="text"
                      error={error?.message}
                      value={value}
                      onChange={onChange}
                      label="Relationship (optional)"
                      name="emergency_contact_relationship"
                      style={{ width: '100%' }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </>
        )}
      </form>
    </PopUp>
  )
}

export default UserForm
